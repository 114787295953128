import PropTypes from 'prop-types';

const objectTypes = [
  'ArticleWord',
  'Assignment',
  'Classroom',
  'Content', // Represents: ExternalEmbed, Article, Poll, Video, AudioTrack, TextSet, Standard
  'ContentBookmark',
  'FullPage', // The entire document provided for a given URL, not just the main contents
  'Integration',
  'PowerWords',
  'Quiz',
  'QuizQuestion',
  'QuizAnswer',
  'Report',
  'WritePrompt',
  'WritePromptResponse',
  'User',
  'Search Suggestion',
];
const actionPrefixes = ['Initiate', 'Cancel', 'Undo'];
const actionNames = [
  'Add',
  'Archive',
  'Create',
  'Delete',
  'Download',
  'Error',
  'Filter',
  'Finish',
  'Hide',
  'Native Print', // Print triggered via an environmentally native interaction
  'Navigate',
  'Page Views',
  'Preview',
  'Print',
  'Read',
  'Search',
  'Share',
  'Sign In',
  'Sign Out',
  'Start',
  'Success',
  'Update',
];
const propertyNames = [
  'date_created',
  'dimension_properties',
  'event_name',
  'platform',
  'platform_version',
  'user_id',
  'user_unique_id',
  'id',
  'event_type',
];
// Event type should represent what triggered the event.
const eventTypes = [
  'click',
  'hover',
  'impression',
  // Use `native-interaction` if the form of user interaction (e.g. click
  // versus keypress) is not knowable. For example, document printing can be
  // triggered through a keypress or through native file menu selections.
  'native-interaction',
  'page-view',
];
const dimensionPropertyNames = [
  'action_name',
  'action_prefix',
  'captured_url',
  'component_type',
  'content_id',
  'content_properties',
  'content_type',
  'context',
  'description',
  'destination_url', // For links
  'error_code',
  'file_url', // Used for image, video, & audio file urls
  'hash',
  'layout_index',
  'legacy_event_name',
  'object_id',
  'object_type',
  'current_url',
  'paragraph_index',
  'parent_row',
  'previous_url',
  'reading_percentage',
  'recommendation_set_ids',
  'referrer',
  'referrer_uri',
  'report_filters',
  'report_type',
  'row_slug',
  'screen_size',
  'scrollX',
  'scrollY',
  'search',
  'search_id',
  'search_term',
  'application',
  'count_results_retrieved',
  'count_results_total',
  'query_term',
  'recommended_strategy',
  'referenced_entities',
  'request_type',
  'result_identifiers',
  'schema_version',
  'request_id',
  'search_parameters',
  'study_segment',
  'used_strategy',
  'user_id',
  'raw_query_string',
  'user_agent',
  'date_created',
  'external_referrer',
  'internal_referrer',
  'error_code'
];

const propTypes = {
  // Provide a legacy name when applicable so that it can
  // be matched to its old event.
  legacyEventName: PropTypes.string,

  /**
   * These three props for the event name.
   * EventName = ObjectName + ActionPrefix + ActionName
   *
   * Ex. 'Content Initiate Assign', 'Assignment Cancel Create', 'WritePrompt Create'
   */
  objectType: PropTypes.oneOf(objectTypes),
  actionPrefix: PropTypes.oneOf(actionPrefixes),
  actionName: PropTypes.oneOf(actionNames).isRequired,

  /**
   * TODO: deprecate content_id prop in favor of new eventStream SLA.
   * Supports the use of Content Type Tracking
   */
  content_id: PropTypes.string,
  /**
   * TODO: deprecate eventProperties prop in favor of new eventStream SLA.
   * Properties to be sent to the trackEvent service that are
   * not a part of the standard schema.
   */
  eventProperties: PropTypes.object,
  // Properties according to new eventStream SLA.
  streamProperties: PropTypes.object,

  /**
   * The size of the user's screen in both pixels and t-shirt sizes.
   */
  screenSize: PropTypes.object,

  // The textset context of user actions.
  context: PropTypes.object,

  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),

  /**
   * Control which event types to track.
   */
  trackView: PropTypes.bool, // Rendered on page.
  trackHover: PropTypes.bool,
  trackClick: PropTypes.bool,
  trackImpression: PropTypes.bool, // Visible in view port.

  eventSource: PropTypes.string, // Impression source.

  /**
   * Component interaction functions to pass through to wrapped component.
   */
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,

  // This is passed in automatically when you use ref
  forwardedRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default propTypes;
export {
  objectTypes,
  actionPrefixes,
  actionNames,
  propertyNames,
  eventTypes,
  dimensionPropertyNames,
};
