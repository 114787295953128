import normalizeUrlForBackendRedirectMatching from './normalizeUrlForBackendRedirectMatching';

// `computeBackendRedirectUrl` checks if the input URL would trigger a redirect
// if we made a request to the backend, and if so returns the redirected URL.
// It accomplishes this by doing a background fetch to the given URL.
const computeBackendRedirectUrl = async(originalUrl) => {
  // The HEAD request here should mean we cause minimal payload overhead cost
  // for both the server and browser, because we should get back just the
  // response headers, no response body. See:
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/HEAD

  // That said, there may be some paths where at the moment, checking for redirects
  // is prohibitively expensive, so we'll check to see if our originalUrl matches
  // any of those and, if it does, prevent it
  const denyListUrlPaths = ['/read'];
  if (denyListUrlPaths.some((path) => originalUrl.includes(path))) {
    return;
  }

  const {url: finalUrl} = await fetch(originalUrl, {method: 'HEAD', redirect: 'follow'});
  if (!finalUrl) return;
  const originalUrlNormalized = normalizeUrlForBackendRedirectMatching(originalUrl);
  const finalUrlNormalized = normalizeUrlForBackendRedirectMatching(finalUrl);
  if (finalUrlNormalized !== originalUrlNormalized) {
    return finalUrl;
  }
};

export default computeBackendRedirectUrl;
