import {REPLACE_STATE} from '../historyApiIntegration.constants';

const registerReplaceStateEvent = () => {
  const originalReplaceState = history.replaceState;
  history.replaceState = function() {
    const replaceStateEvent = new Event(REPLACE_STATE);
    window.dispatchEvent(replaceStateEvent);
    return originalReplaceState.apply(this, arguments);
  };
};

export default registerReplaceStateEvent;
