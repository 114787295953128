import cuid from 'cuid';
import {
  debounce,
  get,
  isFunction,
  keys,
} from 'lodash-es';
import moment from 'moment';

import {propertyNames, dimensionPropertyNames} from 'static/three-oh/src/components/with/WithTrackEventConstants';
import {isNonProdEnvironment} from 'utils/environment';

import getPreviousUrl from './getPreviousUrl';
import fetcher from '../src/modules/apis/ApiFetcher2';

/*
 * Session Activity
 * Event tracking for page views, hover actions, clicks, etc.
 *
 * There are four places session activity is currently sent:
 * 1) Kinesis Firehose stream: User Events. The data ends up in the Redshift
 *    `kinesis.user_events` table. This is widely used by Product and Data teams.
 *    FYI: Whenever you see a "firehose" API call, it's typically going to this stream.
 * 2) Kinesis Firehose stream: Application Tags. This is done via Ser Davos
 *    (https://github.com/newsela/ser-davos) and the data ends up in the S3
 *    `newsela-data-stage` bucket, under `application-tags`. This is not widely
 *    used by anyone, yet. FYI: When the verb "ferry" is used, it is in reference \
 *    to this stream.
 * 3) Google Analytics
 * 4) Elastic APM; our front-end performance monitoring tool. This is primarily
 *    for tracking timing information (when did the hero image on the homepage
 *    load? When was the read page's text available?), and can be queried
 *    in Kibana.
 *
 * This file is an consolidation of HOW we track actions. Because the streams that
 * consume the data we send are not yet fully consolidated, only some actions go to
 * one or the other streams. All PageView events go to application-tags and GA; specific
 * non-Pageview tracking only currently goes to User Events.
*/

// Whenever the format of data that is sent to the tracker changes, increment this number.
const TAG_FORMAT_VERSION = 4;

const EXTERNAL_REFERRER_KEY = 'externalReferrer';
const INTERNAL_REFERRER_KEY = 'internalReferrer';

const NOT_AVAILABLE = 'N/A';
const FALSE = 'false';

// Get a full path with querystrings & hash off location.
const getPath = (location) => {
  const hash = location.hash.replace(/^#/, '').replace('//', '/');
  return location.pathname + location.search + hash;
};

// Let's create a queue of cargo
let cargo = [];

// Send data to the Ser Davos service. If a URL isn't set for that service,
// instead log the tracked data to the console.
const _set_sail = () => {
  const config = {
    basePath: window.SER_DAVOS_URL,
    credentials: 'include',
  };

  // Shift the cargo into the hold and immediately clear the queue for events to keep coming in
  const cargoHold = cargo;
  cargo = [];

  if (cargoHold.length) {
    if (!window.SER_DAVOS_URL) {
      if (!window.SUPPRESS_SER_DAVOS_CONSOLE_LOGGING) {
        /* eslint-disable no-console */
        console.warn('A ferry attempt was made, but Davos is not available.', cargoHold);
      }
      return;
    }
    const data = cargoHold;
    return fetcher.post('/ferry', {data, config}).then(() => {
      // no-op
    }).catch((error) => {
      if (window.Raven) {
        window.Raven.captureException(error);
      }
    });
  }
};

// We don't want to send a bunch of individual HTTP calls to Ser Davos.
// Instead of doing that, wait until all passengers have boarded.
// Don't wait any longer than two seconds, though. Smugglers make no
// guarantees.
const _prepare_to_disembark = debounce(_set_sail, 500, {maxWait: 2000});

// When the page context is about to change, we're going to lose our queue of events.
// Grab all the cargo and go.
window.addEventListener('beforeunload', () => _set_sail());

/**
 * Ferry data via Davos.
 * @param {String} stream
 * @param {Object} payload
 * @param {Function} callback
 *
 */
const ferry = (stream, payload, callback, streamType = 'firehose') => {
  cargo.push({
    stream_name: stream,
    stream_type: streamType,
    payload,
  });

  _prepare_to_disembark();

  const callbackIsFunction = isFunction(callback);
  if (callbackIsFunction) {
    callback();
  }
};

// Ferry PageView
const ferryPageView = (location) => {
  const userId = get(window, 'user_id', null);
  const payload = {
    tag: 'page-view',
    client_date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    user_id: userId,
    path: location.pathname,
    search: location.search,
    hash: location.hash,
    previous_url: getPreviousUrl(),
    version: TAG_FORMAT_VERSION,
    external_referrer: getExternalReferrer(),
    internal_referrer: getInternalReferrer()
  };

  return ferry('application-tags', payload);
};

// Send pageview to Google Analytics
const ga = (path) => {
  const ga = window.ga;
  if (isFunction(ga)) {
    ga('set', 'page', path);
    ga('send', 'pageview');
  }
};

const universalDataProperties = () => {
  return {
    date_created: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    id: cuid(),
    platform: 'web',
    platform_version: 0.0,
    user_id: get(window, 'user_id', null),
    user_unique_id: get(window, 'user.unique_id', null),
  };
};

/**
 * Track any Event Type for User Events
 * @param {Number} userId
 * @param {String} name - The legacy event name based on Object-Action format.
 * @param {String} type - event_type ('hover', 'click', view', etc)
 * @param {Object} properties - schema-less object
 */
const trackEvent = (userId, name, type, properties = {}) => {
  const data = {
    ...universalDataProperties(),
    event_name: name,
    event_type: type,
    previous_url: getPreviousUrl(),
    properties,
    search_id: get(properties, 'search_id'),
    user_id: userId,
  };

  try {
    ferry('user-events', data);
  } catch (error) {
    // Ignore complaints about being tracked.
  }
};

/**
 * Check that the event stream data is formatted properly, and that property
 * names match those from the constants lists. Log exceptions.
 *
 * @param {object} - eventStreamData
 */
const checkStreamProperties = (eventStreamData) => {
  const arrOfProperties = keys(eventStreamData);
  const arrOfDimensionProperties = keys(eventStreamData.dimension_properties);
  const exceptions = [];

  arrOfProperties.forEach((property) => {
    if (!propertyNames.includes(property)) {
      exceptions.push(property);
    }
  });
  arrOfDimensionProperties.forEach((dimensionProperty) => {
    if (!dimensionPropertyNames.includes(dimensionProperty)) {
      exceptions.push(dimensionProperty);
    }
  });

  if (exceptions.length) {
    if (window.Raven) {
      const error = {
        description: 'Event property error',
        exceptions,
        eventStreamData
      };
      window.Raven.captureException(error);
    }
  }

  return exceptions;
};


/**
 * Get event name for event stream.
 *
 * For example:
 * ('Object', 'Prefix', 'Action') becomes 'Object Prefix Action'
 * ('Object', null, 'Action') becomes 'Object Action'
 * (null, null, ' Action ') becomes 'Action'
 *
 * @param {objectType} - object type for the event
 * @param {actionPrefix} - the prefix of the action the event is tracking
 * @param {actionName} - name of the action being tracked
 *
 */
const getEventNameForEventStream = (objectType = '', actionPrefix = '', actionName = '') =>
`${objectType || ''} ${actionPrefix || ''} ${actionName || ''}`.replace(/\s+/g, ' ').trim();

/**
 * Event streaming based on cross team SLA.
 * https://docs.google.com/presentation/d/1hoNfcYEdB95YVTxd9GCvvO7MfQZMJt-fHHT3EcLB0Do/edit?usp=sharing
 *
 * @param {object} - streamEventProperties
 * @param {object} - legacyEvent
 */
const eventStream = (streamEventProperties, legacyEvent = null) => {
  let error = null;
  // Do some basic error handling on stream properties.
  if (!streamEventProperties) {
    error = {
      description: 'streamEventProperties was null or undefined',
      streamEventProperties
    };
  } else if (!streamEventProperties.event_name) {
    error = {
      description: 'Event name is required',
      streamEventProperties
    };
  }

  // If we have an error, don't try to process, but log.
  if (error) {
    if (window.Raven) {
      const error = {
        description: 'Event name is required',
        streamEventProperties
      };
      window.Raven.captureException(error);
    }
    return;
  }

  const eventStreamData = {
    ...streamEventProperties,
    ...universalDataProperties(),
  };

  // Add additional dimension properties.
  Object.assign(eventStreamData.dimension_properties, {
    current_url: window.location.pathname,
    previous_url: getPreviousUrl(),
    scrollX: window.scrollX,
    scrollY: window.scrollY,
    search_id: get(eventStreamData.dimension_properties, 'search_id'),
    external_referrer: getExternalReferrer(),
    internal_referrer: getInternalReferrer()
  });

  // Call legacy tracking to send old events to new stream.
  if (legacyEvent) {
    const {userId, name, type, properties} = legacyEvent;
    trackEvent(userId, name, type, properties);
  }
  // Check all property keys against approved list. Log errors.
  checkStreamProperties(eventStreamData);

  try {
    ferry('event-stream', eventStreamData);
  } catch (error) {
    if (isNonProdEnvironment() && window.Raven) {
      const error = {
        description: 'Event stream error',
        eventStreamData
      };
      window.Raven.captureException(error);
    }
  }
};

// Page views conforming to new SLA.
const ferryPageViewToStream = (location) => {
  const payload = {
    ...universalDataProperties(),
    event_name: 'Page View',
    dimension_properties: {
      description: 'Page view.',
      event_type: 'page-view',
      path: location.pathname,
      search: location.search,
      hash: location.hash,
      previous_url: getPreviousUrl(),
      external_referrer: getExternalReferrer(),
      internal_referrer: getInternalReferrer()
    }
  };
  checkStreamProperties(payload);

  return ferry('event-stream', payload);
};


/**
 * Track specifically the PageView event.
 * Data is sent to application tags and google analytics.
 * Location is optional. It is only necessary for tracking calls that originate
 * from any Angular code.
 * @param {Object} location
 */
const trackPageViewEvent = (location = window.location) => {
  const trackedPath = getPath(location);
  ga(trackedPath);
  ferryPageView(location);
  ferryPageViewToStream(location);
};

const trackPageView = debounce(trackPageViewEvent, 500);

const updateReferrer = (source) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    if (window.sessionStorage.getItem(EXTERNAL_REFERRER_KEY) === null) {
      window.sessionStorage.setItem(EXTERNAL_REFERRER_KEY, document.referrer);
    }
    if (typeof source !== 'undefined') {
      window.sessionStorage.setItem(INTERNAL_REFERRER_KEY, source);
    }
  }
};

const getExternalReferrer = () => {
  let externalReferrer = 'direct';
  if (typeof window !== 'undefined' && window.sessionStorage) {
    externalReferrer = window.sessionStorage.getItem(EXTERNAL_REFERRER_KEY);
    if (externalReferrer === null || externalReferrer === '' || externalReferrer?.indexOf('newsela.com') > -1) {
      externalReferrer = 'direct';
    }
  }
  return externalReferrer;
};

const getInternalReferrer = () => {
  let internalReferrer = '';
  if (typeof window !== 'undefined' && window.sessionStorage) {
    const internalReferrerValue = window.sessionStorage.getItem(INTERNAL_REFERRER_KEY);
    if (internalReferrerValue !== null) {
      internalReferrer = internalReferrerValue;
    }
  }
  return internalReferrer;
};

export {
  trackEvent,
  getEventNameForEventStream,
  eventStream,
  checkStreamProperties,
  trackPageView,
  updateReferrer,
  getExternalReferrer,
  NOT_AVAILABLE,
  FALSE
};
