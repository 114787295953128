import {eventStream, getEventNameForEventStream} from 'utils/sessionActivity';

const trackGlobalEventsEffect = () => {
  const trackBeforePrintEvent = () => {
    eventStream({
      event_name: getEventNameForEventStream('FullPage', 'Initiate', 'Native Print'),
      event_type: 'native-interaction',
      dimension_properties: {},
    });
  };
  globalThis.addEventListener('beforeprint', trackBeforePrintEvent);
  return () => {
    globalThis.removeEventListener('beforeprint', trackBeforePrintEvent);
  };
};

export default trackGlobalEventsEffect;
