import {PUSH_STATE} from '../historyApiIntegration.constants';

const registerPushStateEvent = () => {
  const originalPushState = history.pushState;
  history.pushState = function() {
    const result = originalPushState.apply(this, arguments);
    const pushStateEvent = new Event(PUSH_STATE);
    window.dispatchEvent(pushStateEvent);
    return result;
  };
};

export default registerPushStateEvent;
