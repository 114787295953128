/*
 * non-production environment here includes localhost
 * and any subdomain staging environment (i.e. regex checking this)
 */
export function isNonProdEnvironment() {
  const hostname = window.location.hostname;
  const stagingEnvDomain = '.newsela.com';
  const featureEnvDomain = '.newseladev.com';
  // TODO-LUCIO: Remove it before the final PR
  if (hostname.includes('google-classroom-addon')) {
    return false;
  }

  return (hostname.includes('localhost') || hostname.endsWith(stagingEnvDomain) || hostname.endsWith(featureEnvDomain));
}

export const isLocalEnvironment = () => {
  return window.location.hostname === 'localhost';
};

export const isProductionEnvironment = () => {
  return window.location.host === 'newsela.com';
};

export const isStagingEnvironment = () => {
  return window.location.host === 'staging.newseladev.com';
};

export const isFeatureEnvironment = () => {
  return (
    !isLocalEnvironment() &&
    !isProductionEnvironment() &&
    !isStagingEnvironment()
  );
};
