const parseUrl = (url) => {
  // The easy way to parse an URL, is to create <a> element. See:
  // https://gist.github.com/jlong/2428561
  // The problem with the built-in URL constructor is that it throws errors for
  // URL strings that don't include an origin, such as `../foo` or `/bar`.
  const anchorElem = document.createElement('a');
  anchorElem.href = url;
  return {
    hash: anchorElem.hash,
    host: anchorElem.host,
    hostname: anchorElem.hostname,
    href: anchorElem.href,
    origin: anchorElem.origin,
    pathname: anchorElem.pathname,
    port: anchorElem.port,
    protocol: anchorElem.protocol,
    search: anchorElem.search,
  };
};

export default parseUrl;
