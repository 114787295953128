import {PUSH_STATE, REPLACE_STATE} from '../../historyApiIntegration/historyApiIntegration.constants';
import computeBackendRedirectUrl from '../computeBackendRedirectUrl';

// `initializeObeyBackendRedirects` ensures that any frontend routing obeys
// backend redirects.
const initializeObeyBackendRedirects = () => {
  window.addEventListener(PUSH_STATE, async() => {
    const redirectUrl = await computeBackendRedirectUrl(window.location.href);
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  });

  window.addEventListener(REPLACE_STATE, async() => {
    const redirectUrl = await computeBackendRedirectUrl(window.location.href);
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  });
};

export default initializeObeyBackendRedirects;
