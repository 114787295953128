import parseUrl from 'utils/parseUrl';

// `normalizeUrlForBackendRedirectMatching` returns a string that can serve to
// determine whether two URLs are equivalent, for the sake of redirecting from
// one to the other. For example, if we have A as the current URL and B as the
// potential URL to redirect to, if A and B are the same except that one has a
// trailing slash and the other does not, that should not trigger a redirect.
const normalizeUrlForBackendRedirectMatching = (url) => {
  const {origin, pathname} = parseUrl(url);
  const normalizedPathname = `${pathname}${pathname.endsWith('/') ? '' : '/'}`;
  return origin + normalizedPathname;
};

export default normalizeUrlForBackendRedirectMatching;
