import ownHistory from 'utils/ownHistory';

/**
 * Getting the previous URL is a little tricky. The global `document.referrer`
 * works for top level page navigation URL changes. However, many "pages" also
 * use react router, which uses the history API, which in turn does *not*
 * update the `document.referrer` on URL changes. So here we account for that
 * discrepancy by setting the previous URL to the `document.referrer` by
 * default and updating that in memory as a result of any history API changes.
 */

let currentUrl = window.location.href;
let previousUrl = document.referrer;

const getPreviousUrl = () => previousUrl;

ownHistory.listen(({pathname, search, hash}) => {
  previousUrl = currentUrl;
  currentUrl = new URL(`${pathname}${search}${hash}`, window.location.origin).href;
});

export default getPreviousUrl;
